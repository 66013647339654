export enum ROLE_NAME {
  Administrator = 'Administrator',
  CareManager = 'CareManager',
  DeviceOperations = 'DeviceOperations',
  ClinicalOperations = 'ClinicalOperations',
  VASales = 'VASales',
  CommercialSales = 'CommercialSales',
  SoftwareDeveloper = 'SoftwareDeveloper',
  CareManagerSupervisor = 'CareManagerSuperviser', // Important: keep spelling mistake as that is role name in database
  VASalesSupervisor = 'VASalesSuperviser', // Important: keep spelling mistake as that is role name in database
  CommercialSalesSupervisor = 'CommercialSalesSuperviser', // Important: keep spelling mistake as that is role name in database
  EscalationsWorklistAccess = 'EscalationsWorklistAccess',
  CohortAdministrator = 'CohortAdministrator'
}

export enum ACCESS_NAME {
  AdminMenu = 'AdminMenu',
  HomeMenu = 'HomeMenu',
  DashboardsMenu = 'DashboardsMenu',
  CustomersMenu = 'CustomersMenu',
  PatientsMenu = 'PatientsMenu',
  PurchaseOrdersMenu = 'PurchaseOrdersMenu',
  PrescriptionsMenu = 'PrescriptionsMenu',
  PatientDeviceMenu = 'PatientDeviceMenu',
  AboutMenu = 'AboutMenu',
  LogoutMenu = 'LogoutMenu',
  FlexWorklistAccess = 'FlexWorklistAccess',
  EscalationsWorklistAccess = 'EscalationsWorklistAccess',
  EditPatientWorklistItemCadence = 'EditPatientWorklistItemCadence',
  EditCustomerWorklistItemCadence = 'EditCustomerWorklistItemCadence',
  PodMenu = 'PodMenu',
  StartCohort = 'StartCohort'
}

export enum ESCALATION_TYPE {
  Clinical = 1,
  ClinicalAdvisory = 2,
  NonClinicalPatientUpdate = 3,
  NonClinicalDevice = 4,
  NonClinicalInternalRecords = 5,
  OnboardingUnfulfilled = 6,
  OnboardingUntrained = 7,
  Withdrawal = 8,
  InternalClinical = 9
}

export enum PATIENT_STATUS {
  Withdrawn = 'Withdrawn',
  Active = 'Active',
  Inactive = 'Inactive'
}

export enum ENTITY_TYPE {
  Patient = 1,
  Provider = 2,
  Caregiver = 3,
  PurchaseOrder = 4,
  Prescription = 5,
  Member = 6,
  Practice = 7,
  PrequalificationProvider = 8
}

export enum SYSTEM_ROLE {
  Administrator = 1,
  CareManager = 2,
  DeviceOperations = 3,
  ClinicalOperations = 4,
  VASales = 5,
  CommercialSales = 6,
  SofwareDeveloper = 7,
  CareManagerSuperviser = 8,
  VASalesSuperviser = 9,
  CommercialSalesSuperviser = 10,
  EscalationsWorklistUser = 11,
  CohortAdministrator = 12
}

export enum POD_ROLE {
  PodAdministrator = 1,
  PodTeamLeader = 2,
  PodSupervisor = 3,
  PodNurse = 4,
  PodRep = 5
}

export enum PROVIDER_TYPE {
  Podiatrist = 1,
  PCP = 2,
  Endocrinologist = 3,
  Vascular = 4,
  Other = 5
}

export enum HISTORY_TAB_NAMES {
  ClinicalHistory = 'CLINICAL_HISTORY',
  SubjectiveQuestions = 'SUBJECTIVE_QUESTIONS',
  OtherQuestion = 'OTHER_QUESTION',
  MedicalHistory = 'MEDICAL_HISTORY_QUESTIONS',
  SocialHistory = 'SOCIAL_HISTORY',
  SurgicaHistory = 'SURGICAL_HISTORY'
}

export enum WORKLIST_ITEM_STATUS_TYPE {
  Open = 1,
  Closed = 2,
  Snoozed = 3
}

export enum WORKLIST_ITEM_TYPE {
  InitialOutreach = 1,
  Training = 2,
  Onboarding = 3,
  Offloading = 4,
  Reengagement = 5,
  Resolution = 6,
  ClinicFeedback = 7,
  Inbound = 8,
  Wellness = 9,
  DeviceAction = 10,
  PatientCallRequested = 11,
  Escalation = 12,
  Replacement = 13,
  Renewal = 14,
  Prequalification = 15,
  InflammationCheckin = 16,
  CompleteOnboarding = 17,
  PostRenewal = 18,
  Withdrawal = 19,
  Reactivation = 20
}

export enum WORKLIST_TYPE {
  CM = 1,
  ClinicalOps = 2,
  DeviceOps = 3,
  VA = 4,
  Commercial = 5
}
