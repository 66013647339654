import { type PodAssignmentsTableDataProps } from '@/interface/PodAssignmentInterface';
import apolloClient from '@/lib/apollo/apollo-client';
import {
  ADD_POD_ASSIGNMENTS,
  BULK_NURSE_ASSIGNMENT,
  DELETE_PATIENT_FROM_POD,
  DELETE_POD,
  DELETE_POD_ASSIGNMENTS,
  GET_PATIENT_WITHOUT_POD,
  GET_POD_ASSIGNMENTS,
  GET_POD_INFO,
  GET_POD_PATIENTS,
  GET_POD_ROLES,
  GET_USERS_BY_POD_ROLE,
  SAVE_POD,
  UPDATE_POD,
  UPDATE_PRIMARY_NURSE_SINGLE
} from '@/lib/apollo/queries';
import { type Customer } from '@/schemas/customers';
import { type UnassignedPodTableItem } from '@/schemas/nursing';
import { type Patient } from '@/schemas/patient';
import { type PodAndAssignments } from '@/schemas/pods';
import { AchillesUser } from '@/schemas/users';
import { type GetPodPatientsType } from '@/schemas/v2/graphs';
import { podiAxios } from '@/utils/api';
import { POD_ROLE } from '@/utils/enums';
import { type ServersideDataQueryOptions } from '@/utils/types';
import { orderBy } from 'lodash';
import { type PatientsWithoutPodApiRes, type PodsListApiRes } from '../schemas/APISchema';

export const getPodsAndAssignments = () => {
  return podiAxios<PodAndAssignments[]>(`/api/pods/assignments`).then((podsAndAssignments) => orderBy(podsAndAssignments, 'name', 'asc'));
};

export const getUnassignedPods = ({ Page, ItemsPerPage }: Partial<ServersideDataQueryOptions>) => {
  const params = new URLSearchParams({ Page, ItemsPerPage } as unknown as Record<string, string>);
  return podiAxios<{ items: UnassignedPodTableItem[]; totalCount: number }>(`/api/pods/patients/unassigned?${params}`);
};

/* --------------------------------- GraphQL -------------------------------- */
export type GQLPod = PodsListApiRes['pods']['data'][0];

export interface Pod {
  createdByUserId: number;
  description: string;
  isActive: boolean;
  isDeleted: boolean;
  isFloat: boolean;
  name: string;
  podId: number;
  updatedByUserId: number;
}

export const addPod = ({ pod }: { pod: Partial<Pod> }) => {
  return new Promise((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: SAVE_POD,
        variables: { input: pod }
      })
      .then((response) => {
        if (response?.data?.addPod?.error) {
          reject(response?.data?.addPod?.error);
        } else {
          resolve(response?.data?.addPod);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updatePod = ({ pod }: { pod: Partial<Pod> }) => {
  return new Promise((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: UPDATE_POD,
        variables: { input: pod }
      })
      .then((response) => {
        if (response?.data?.updatePod?.error) {
          reject(response?.data?.updatePod?.error);
        } else {
          resolve(response?.data?.updatePod);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deletePod = ({ podId, userId }: { podId: GQLPod['podId']; userId: number }) => {
  return new Promise((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: DELETE_POD,
        variables: {
          podId,
          userId
        }
      })
      .then((response) => {
        if (response?.data?.deletePod?.error) {
          reject(response?.data?.deletePod?.error);
        } else {
          resolve(response?.data?.deletePod);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

interface PodInfoResponse {
  podId: GQLPod['podId'];
  name: string;
  description: string;
  customers: { data: { customerId: Customer['customerId']; name: string }[]; sites: { data: { siteId: number; name: string } } };
  nurses: { count: number; data: { userId: number; displayName: string }[] };
}

export const getPodInfo = ({ podId }: { podId: GQLPod['podId'] }) => {
  return new Promise<PodInfoResponse>((resolve, reject) => {
    apolloClient
      .query<{ pod: PodInfoResponse }>({
        query: GET_POD_INFO,
        fetchPolicy: 'network-only',
        variables: {
          podId,
          customerInput: {
            podId,
            take: 1000,
            skip: 0
          },
          siteInput: {
            take: 1000,
            skip: 0
          },
          podRoleIds: [POD_ROLE.PodNurse],
          assignedUsersByPodRoleInput: {
            take: 1000,
            skip: 0
          }
        }
      })
      .then((response) => {
        if (response.errors) {
          reject(response.errors);
        } else {
          resolve(response?.data?.pod);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

interface PodRole {
  podRoleId: number;
  description: string;
  name: string;
}

export const getPodRoles = ({ podId }: { podId: GQLPod['podId'] }) => {
  return new Promise<PodRole[]>((resolve, reject) => {
    apolloClient
      .query<{ pod: { podRoles: { data: PodRole[] } } }>({
        query: GET_POD_ROLES,
        fetchPolicy: 'network-only',
        variables: { podId }
      })
      .then((response) => {
        if (response.errors) {
          reject(response.errors);
        } else {
          resolve(response?.data?.pod?.podRoles.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getUnassignedPodsCount = () => {
  return new Promise<{ count: number }>((resolve, reject) => {
    apolloClient
      .query<PatientsWithoutPodApiRes>({
        query: GET_PATIENT_WITHOUT_POD,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            skip: 0,
            take: 100
          }
        }
      })
      .then((response) => {
        if (response?.errors) {
          reject(response?.errors);
        } else {
          resolve({ count: response?.data?.patientsWithoutPod?.count || 0 });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getPodAssignments = ({
  podId,
  podAssignmentInput
}: {
  podId: GQLPod['podId'];
  podAssignmentInput: {
    skip: number;
    take: number;
    sort: {
      column: string;
      order: 'asc' | 'desc';
    };
  };
}) => {
  return new Promise<PodAssignmentsTableDataProps[]>((resolve, reject) => {
    apolloClient
      .query({
        query: GET_POD_ASSIGNMENTS,
        fetchPolicy: 'network-only',
        variables: { podId, podAssignmentInput }
      })
      .then((response) => {
        if (response?.errors) {
          reject(response?.errors);
        } else {
          const mapPodAssignments = (data: any[]): PodAssignmentsTableDataProps[] => {
            return (data || []).map((item) => ({
              podAssignmentId: item.podAssignmentId,
              userId: item?.user?.userId,
              userFirstName: item?.user.firstName,
              userLastName: item?.user.lastName,
              podRoleId: item?.podRole?.podRoleId,
              podRoleDescription: item?.podRole?.description
            }));
          };

          resolve(mapPodAssignments(response?.data?.pod?.assignments?.data));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export interface PodAssignment {
  userId: number;
  podId: number;
  createdByUserId: number;
  podRoleId?: number;
}

export const addPodAssignments = ({ assignments }: { assignments: PodAssignment[] }) => {
  return new Promise<boolean>((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: ADD_POD_ASSIGNMENTS,
        variables: { input: { assignments } }
      })
      .then((response) => {
        if (response?.data?.payload?.error) {
          reject(response?.data?.payload?.error);
        } else {
          resolve(response?.data?.payload);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deletePodAssignment = ({ podId, podAssignmentId, userId }: { podId: GQLPod['podId']; podAssignmentId: number; userId: number }) => {
  return new Promise((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: DELETE_POD_ASSIGNMENTS,
        variables: {
          input: {
            podRoleId: null,
            podId,
            updatedByUserId: userId,
            podAssignmentIds: [podAssignmentId]
          }
        }
      })
      .then((response) => {
        if (response?.data?.payload?.error) {
          reject(response?.data?.payload?.error);
        } else {
          resolve(response?.data?.payload);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getPodPatients = ({ ItemsPerPage = 100, Page = 1, PodId, SortBy, SortDesc, Search }: Partial<ServersideDataQueryOptions>) => {
  interface Variables {
    podId: GQLPod['podId'];
    podRoleIds?: POD_ROLE[];
    podPatientInput?: {
      skip: number;
      take: number;
      sort: { column?: string; order?: 'asc' | 'desc' };
      searchWord: string;
      filter: {};
    };
    assignedUsersByPodRoleInput: { take: number; skip: number };
  }

  // remap standard ServersideDataQueryOptions to support TableServerside built-in functionality
  const variables: Variables = {
    podId: PodId as number,
    podRoleIds: [POD_ROLE.PodNurse],
    podPatientInput: {
      take: ItemsPerPage,
      skip: (Page - 1) * ItemsPerPage, // "Page" property of "ServersideDataQueryOptions" is 1-based
      sort: SortBy
        ? {
            column: SortBy,
            order: SortDesc ? 'desc' : 'asc'
          }
        : {},
      searchWord: Search ?? '',
      filter: {}
    },
    assignedUsersByPodRoleInput: {
      take: 100,
      skip: 0
    }
  };

  return new Promise<GetPodPatientsType['pod']>((resolve, reject) => {
    apolloClient
      .query<GetPodPatientsType>({
        query: GET_POD_PATIENTS,
        fetchPolicy: 'network-only',
        variables
      })
      .then((response) => {
        if (response?.errors) {
          reject(response?.errors);
        } else {
          resolve(response?.data.pod);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export type PodPatientTableItem = GetPodPatientsType['pod']['patients']['data'][0] & { primaryNurseUserDisplayName: string | null };

export const getPodPatientsTable = ({
  ItemsPerPage = 10,
  Page = 1,
  PodId,
  SortBy,
  SortDesc,
  Search
}: Partial<ServersideDataQueryOptions>): Promise<{ items: PodPatientTableItem[]; totalCount: number }> => {
  return getPodPatients({ ItemsPerPage, Page, PodId, SortBy, SortDesc, Search }).then((response) => {
    return {
      totalCount: response.patients.count,
      items: response.patients.data.map((patient) => ({
        ...patient,
        primaryNurseUserDisplayName: patient.primaryNurseUser?.displayName
      }))
    };
  });
};

export const addPodPatient = ({
  customerId,
  patientIds,
  podId,
  updatedByUserId
}: {
  customerId: Customer['customerId'];
  patientIds: Patient['patientId'][];
  podId: GQLPod['podId'];
  updatedByUserId: number;
}) => {
  return new Promise((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: DELETE_PATIENT_FROM_POD,
        variables: { input: { customerId, patientIds, podId, updatedByUserId } }
      })
      .then((response) => {
        if (response?.data?.payload?.error) {
          reject(response?.data?.payload?.error);
        } else {
          resolve(response?.data?.payload);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deletePodPatient = ({
  customerId,
  patientIds,
  podId,
  updatedByUserId
}: {
  customerId: Customer['customerId'];
  patientIds: Patient['patientId'][];
  podId: GQLPod['podId'];
  updatedByUserId: number;
}) => {
  return new Promise<boolean>((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: DELETE_PATIENT_FROM_POD,
        variables: {
          input: { customerId, patientIds, podId, updatedByUserId }
        }
      })
      .then((response) => {
        if (response?.data?.payload?.error) {
          reject(response?.data?.payload?.error);
        } else {
          resolve(response?.data?.payload);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const assignPrimaryNurse = ({
  customerId,
  patientId,
  primaryNurseUserId,
  updatedByUserId
}: {
  customerId: Customer['customerId'];
  patientId: Patient['patientId'];
  primaryNurseUserId: Patient['primaryNurseUserId'];
  updatedByUserId: Patient['updatedByUserId'];
}) => {
  return new Promise<boolean>((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: UPDATE_PRIMARY_NURSE_SINGLE,
        variables: {
          input: {
            customerId,
            patientId,
            primaryNurseUserId,
            updatedByUserId
          }
        }
      })
      .then((response) => {
        if (response?.data?.payload?.error) {
          reject(response?.data?.payload?.error);
        } else {
          resolve(response?.data?.payload);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const bulkAssignPrimaryNurse = ({
  updatedBy,
  assignments,
  podId,
  updatedByUserId,
  primaryNurseUserId,
  bulkPatients
}: {
  assignments: any[];
  bulkPatients: {
    patientId: Patient['patientId'];
    customerId: Customer['customerId'];
    patientFirstName: Patient['firstName'];
    patientLastName: Patient['lastName'];
  }[];
  podId: GQLPod['podId'];
  primaryNurseUserId: Patient['primaryNurseUserId'];
  updatedBy: string | undefined;
  updatedByUserId: Patient['updatedByUserId'];
}) => {
  return new Promise<boolean>((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: BULK_NURSE_ASSIGNMENT,
        variables: {
          input: {
            updatedBy,
            assignments,
            podId,
            updatedByUserId,
            primaryNurseUserId,
            bulkPatients
          }
        }
      })
      .then((response) => {
        if (response?.data?.payload?.error) {
          reject(response?.data?.payload);
        } else {
          resolve(response?.data?.payload);
        }
      })
      .catch((error) => {
        reject(error?.data?.payload);
      });
  });
};

export interface GetUsersByPodRoleResponse {
  pod: {
    __typename: string;
    podRole: {
      __typename: string;
      podRoleId: number;
      users: {
        __typename: string;
        count: number;
        data: {
          __typename: 'User';
          userId: number;
          firstName: string;
          lastName: string;
          avatarURL: string;
          email: string;
        }[];
      };
    };
  };
}

export const getUsersByPodRole = ({
  podId,
  podRoleId,
  userInput = { skip: 0, take: 1000, searchWord: '' }
}: {
  podId: GQLPod['podId'];
  podRoleId: PodRole['podRoleId'];
  userInput?: {
    skip: number;
    take: number;
    searchWord: string;
  };
}) => {
  return new Promise<Partial<AchillesUser>[]>((resolve, reject) => {
    apolloClient
      .query<GetUsersByPodRoleResponse>({
        query: GET_USERS_BY_POD_ROLE,
        fetchPolicy: 'network-only',
        variables: {
          podId,
          podRoleId,
          userInput
        }
      })
      .then((response) => {
        if (response?.errors) {
          reject(response?.errors);
        } else {
          const mapResponseToAchillesUsers = (data: GetUsersByPodRoleResponse['pod']['podRole']['users']['data']): Partial<AchillesUser>[] => {
            return (data || []).map((item) => ({
              userId: item.userId,
              firstName: item.firstName,
              lastName: item.lastName,
              avatarUrl: item.avatarURL,
              email: item.email
            }));
          };

          resolve(mapResponseToAchillesUsers(response?.data?.pod?.podRole?.users?.data));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
