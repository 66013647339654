'use client';

import { type PodAndAssignments } from '@/schemas/pods';
import { type AchillesUser } from '@/schemas/users';
import { type Pod, type PodAssignment, getPodsAndAssignments } from '@/services/pods';
import { getUsers } from '@/services/users';
import { type WorklistItemGroup } from '@/utils/constants';
import { type ACCESS_NAME, ROLE_NAME } from '@/utils/enums';
import { queryConfig } from '@/utils/react-query';
import * as Sentry from '@sentry/nextjs';
import { useQuery } from '@tanstack/react-query';
import { type Session } from 'next-auth';
import { useSession } from 'next-auth/react';
import { type Dispatch, type ReactNode, type SetStateAction, createContext, useContext, useEffect, useState } from 'react';
export const AuthContext = createContext<AuthContextProps>(null as unknown as AuthContextProps);
interface AuthContextProps {
  activePodiUsers: AchillesUser[];
  activeUsers: AchillesUser[];
  findUser: (user: Partial<AchillesUser>) => AchillesUser | undefined;
  getUsersWithAccess: (accessName: string) => AchillesUser[];
  getUsersWithRole: (roleName: ROLE_NAME) => AchillesUser[];
  hasAccess: (accessName: ACCESS_NAME) => boolean;
  hasRole: (roleName: ROLE_NAME) => boolean;
  hasMultipleRoles: () => boolean;
  isOnlyCareManager: () => boolean;
  podsAndAssignments: PodAndAssignments[] | undefined;
  selectedWorklistItemGroup: WorklistItemGroup | null;
  selectedPodId: Pod['podId'];
  session: Session | null;
  setSelectedPodId: Dispatch<SetStateAction<Pod['podId']>>;
  setSelectedWorklistItemGroup: Dispatch<SetStateAction<WorklistItemGroup | null>>;
  setUser: Dispatch<SetStateAction<AchillesUser | undefined>>;
  user: AchillesUser | undefined;
  userPodAssignments: PodAssignment[] | undefined;
  users: AchillesUser[] | undefined;
}
const achillesUserToSentryUser = ({
  email,
  userId,
  firstName,
  lastName
}: AchillesUser): Sentry.User => {
  return {
    id: userId,
    username: [firstName, lastName].join(' '),
    email,
    ip_address: '{{auto}}'
  };
};
export const AuthProvider = (props: {
  children: ReactNode;
}): JSX.Element => {
  const session = useSession()?.data;
  const {
    data: users
  } = useQuery({
    ...queryConfig('getUsers', getUsers),
    enabled: !!session?.user?.accessToken,
    retry: false
  });
  // TODO: Evaluate setting Sentry release via this network call vs. env variable approach.
  // const { data: releases } = useQuery({ ...queryConfig('getReleases', getReleases), enabled: !!session?.user?.accessToken, retry: false });
  const [user, setUser] = useState<AchillesUser>();
  const {
    data: podsAndAssignments
  } = useQuery({
    ...queryConfig('getPodsAndAssignments', getPodsAndAssignments),
    enabled: !!user?.userId
  });
  const [selectedPodId, setSelectedPodId] = useState<Pod['podId']>(0);
  const [selectedWorklistItemGroup, setSelectedWorklistItemGroup] = useState<WorklistItemGroup | null>(null);
  useEffect(() => {
    if (!session || !users?.length || !!user) return;
    const _user = users.find(u => u.email.toLowerCase().trim() === (session.user.email || '').toLowerCase().trim());
    if (_user) {
      setUser(_user);
      Sentry.setUser(achillesUserToSentryUser(_user));
    } else {
      Sentry.setUser({
        email: session.user.email || ''
      });
      Sentry.captureException(new Error('User not found in Achilles.'));
    }
  }, [session, users]);
  const findUser = ({
    userId,
    email
  }: Partial<AchillesUser>): AchillesUser | undefined => {
    return users?.find(user => user.userId === userId || user.email === email);
  };

  // Role checks
  const hasRole = (roleName: ROLE_NAME) => {
    return !!user?.userRoles.some(userRole => userRole?.role?.name === roleName);
  };
  const hasMultipleRoles = (): boolean => {
    return (user?.userRoles?.length || 0) > 1;
  };
  const getUsersWithRole = (roleName: ROLE_NAME): AchillesUser[] => {
    return (users || []).filter(user => user.userRoles.some(role => role.role?.name === roleName));
  };

  // User has specified role, and no others
  const isOnlyCareManager = () => hasRole(ROLE_NAME.CareManager) && !hasMultipleRoles();

  // Access checks
  const hasAccess = (accessName: ACCESS_NAME) => {
    return !!user?.userRoles.some(userRole => userRole?.role?.roleAccesses?.some(accesses => accesses?.access?.name === accessName));
  };
  const getUsersWithAccess = (accessName: string): AchillesUser[] => {
    return (users || []).filter(user => user.userRoles.some(role => role.role.roleAccesses.some(access => access.access?.name === accessName)));
  };
  const value: AuthContextProps = {
    activePodiUsers: (users || []).filter(user => user.email.includes('@podimetrics.com') && !user.isServiceAccount && user.isActive),
    activeUsers: (users || []).filter(user => user.isActive),
    findUser,
    getUsersWithAccess,
    getUsersWithRole,
    hasAccess,
    hasRole,
    hasMultipleRoles,
    isOnlyCareManager,
    selectedPodId,
    selectedWorklistItemGroup,
    session,
    setSelectedPodId,
    setSelectedWorklistItemGroup,
    setUser,
    user,
    podsAndAssignments,
    userPodAssignments: (podsAndAssignments || []).reduce((acc, pod) => {
      const podAssignment = (user?.userId && pod.podAssignments?.length ? pod.podAssignments.find(assignment => assignment?.userId === user?.userId) : undefined) as undefined;
      if (podAssignment) acc.push(podAssignment);
      return acc;
    }, [] as PodAssignment[]),
    users
  };
  return <AuthContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="AuthProvider" data-sentry-source-file="useAuth.tsx">{props.children}</AuthContext.Provider>;
};
export const useAuth = (): AuthContextProps => useContext(AuthContext);